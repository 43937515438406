.row-text {
	padding-top: 16px;
}
.negative, .valid {
    color: var(--doc-green);
    font-weight: bold;
}
.rejected, .positive {
    color: var(--doc-pink);
    font-weight: bold;
}
.invalid {
    font-weight: bold;
}
.short-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}
