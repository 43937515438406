.loading-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--doc-pink);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
