@mixin add-color($argument) {
    $string: unquote($argument);

    &#{$string} {
        color: var(unquote($argument)) !important;
    }
}

.color {
    @include add-color(--doc-black);
    @include add-color(--doc-dark-grey);
    @include add-color(--doc-light-grey);
    @include add-color(--doc-white);
    @include add-color(--doc-pink);
    @include add-color(--doc-green);
    @include add-color(--doc-orange);
    @include add-color(--doc-light-pink);
    @include add-color(--doc-input-blue);
}
