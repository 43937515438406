.login-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--doc-white);
	width: 400px;
	max-width: 95%;
	box-sizing: border-box;
	padding: 10px;
	border-radius: 3px;
	.login-form {
		box-sizing: border-box;
		> form {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}
		.link-helper {
			color: var(--doc-pink);
			cursor: pointer;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.row {
		padding-top: 10px;
		width: 100%;
		text-align: center;
	}
	.row-left {
		padding-bottom: 10px;
		width: 100%;
		text-align: left;
	}
}
@media screen and (max-width: 768px) {
	.login-container {
		min-width: 90%;
	}
}
