.invalid.PhoneInput {
	border-bottom: 2px solid var(--doc-error);
	color: var(--doc-error);
	.PhoneInputCountry {
		color: var(--doc-error);
	}
	input::placeholder {
		color: var(--doc-error);
	}
	.PhoneInputInput {
		color: var(--doc-dark-grey);
	}
}
.PhoneInput {
	max-width: 100%;
	max-height: 100%;
	height: 56px;
	border-bottom: 1px solid var(--doc-dark-grey) !important;
	color: var(--doc-dark-grey);
	box-sizing: border-box;
	&:focus {
		outline: none;
	}
	.PhoneInputInput {
		box-sizing: border-box;
		font-size: 16px;
		border: none;
		background-color: var(--doc-light-grey);
		height: 100%;
		background-color: rgba(0, 0, 0, 0.09);
		color: var(--doc-dark-grey);
		border-top-right-radius: 4px;
		&:focus {
			outline: none;
		}
	}
	.PhoneInputCountry {
		background-color: rgba(0, 0, 0, 0.09);
		padding-right: 10px;
		padding-left: 12px;
		border-top-left-radius: 3px;
		margin-right: 0px;
	}
}
.PhoneInput--focus {
	border-bottom: 2px solid var(--doc-input-blue);
	color: var(--doc-input-blue);
	&:invalid {
		border-bottom: 2px solid var(--doc-error);
		color: var(--doc-error);
	}
	.PhoneInputCountry {
		color: var(--doc-blue);
	}
	.PhoneInputCountryIcon {
		color: var(--doc-blue);
	}
	input {
		color: var(--doc-blue);
	}
}
