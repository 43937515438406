.top-container {
  height: 100px;
  transition: all linear 0.5s;
  position: absolute;
  p {
    font-weight: 500;
    font-size: 20px;
    width: max-content;
    padding: 0 10px;
  }
}
.photo-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  .text-box {
    background-color: rgba(239, 239, 240, 0.6);
    border-radius: 25px;
    align-items: center;
    display: flex;
    height: 50%;
    margin-right: 10px;
    p {
      margin: 0;
      align-self: center;
    }
  }
}
.right {
  right: 30px;
}
.control-container {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.red-icon {
  color: var(--doc-pink);
}
.select-form {
  width: 100%;
  background-color: rgba(239, 239, 240, 0.6);
}
.select-container {
  width: 200px;
  height: 180%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.control-item {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba(239, 239, 240, 0.7);
  background-color: rgba(239, 239, 240, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 20px;
  }
}
.scanning {
  background-color: var(--doc-green);
}
.controls-container {
  width: 200px;
  max-width: 40%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  transition: all linear 0.5s;
  position: absolute;
  bottom: 0px;
  z-index: 2;
}
  .photo-controller {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.5s linear;
    z-index: 102;
    p {
      position: absolute;
      bottom: -40px;
      width: 100px;
      text-align: center;
      background-color: rgba(239, 239, 240, 0.6);
      padding: 2px;
    }
  }
  .photo-controller.active {
    color: var(--doc-dark-grey);
    &:active {
      color: var(--doc-pink);
    }
    z-index: 102;
  }
.controls-container.photo-mode {
  max-width: 100vw;
  left: 0px;
}

@media only screen and (max-width: 768px) {
  .controls-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .controls-container.photo-mode {
    max-width: 100vw;
    left: 0px;
  }
}
