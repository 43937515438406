.chat-room-container {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 16px;
  background-color: var(--doc-white);
  box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px;
}

.room-name {
  margin: 0 !important;
  padding-right: 5px;
  font-size: 16px;
}

.message-author {
  font-weight: bold;
}

.online {
  width: 12px !important;
  color: var(--doc-green);
}

.offline {
  width: 12px !important;
  color: var(--doc-pink);
}

.message-image {
  max-width: 180px;
}

.time {
  color: var(--doc-grey);
}

.my-message-title {
  text-align: right;
}

.message-title {
  font-weight: 500;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 5px;
}

.messages-container {
  min-height: 330px;
  max-height: 330px;
  overflow-y: scroll;
  padding: 0 20px 0 0;
}

.chat-container {
  flex: 1;
  min-height: 300px;
  overflow-y: auto;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  display: flex;
  padding: 10px;
}

.messages-list {
  list-style-type: none;
  padding: 0;
}

.new-message-input-field {
  font-size: 20px;
  padding: 8px 12px;
  resize: none;
}

.message-item {
  width: max-content;
  max-width: 50%;
  white-space: pre-line;
  word-break: break-word;
  margin-bottom: 10px;
  border-radius: 4px;
  color: black;
}

.my-message {
  margin-left: auto;
}

.received-message {
  margin-right: auto;
}

.send-message-button {
  font-size: 28px;
  font-weight: 600;
  color: white;
  background: #31a24c;
  padding: 24px 12px;
  border: none;
}

.messages-container, .new-message-input-field, .send-message-button {
  border-color: #9a9a9a;
}