.card-items-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	max-width: 800px;
	margin: auto;
	padding-top: 20px;
	.card-item {
		color: var(--doc-dark-grey);
		box-sizing: border-box;
		max-width: 95%;
		width: 300px;
		min-width: 220px;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		height: 310px;
	}
}
.card-items-container > * {
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 10px;
	margin-right: 10px;
}
