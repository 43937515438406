.video-call-container {
	position: absolute;
	top: 0px;
	bottom: 0px;
	width: 100%;
	background-color: var(--doc-dark-grey);
	z-index: 1;
}
.shadow-online {
	box-shadow: rgb(0, 128, 128) 0px 30px 60px -12px inset, rgb(0, 128, 128) 0px 18px 36px -18px inset;
}
.message-background {
	position: absolute;
	top: 40%;
	left: 50%;
	color: var(--doc-white);
	transform: translate(-50%, -50%);
	font-size: 28px;
	text-align: center;
	z-index: -2;
}
.red-bold-text {
	font-weight: 700 !important;
	color: var(--doc-pink) !important;
}
.make-call {
	position: absolute;
	top: 0px;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	.button-container {
		background-color: var(--doc-light-grey);
		padding: 30px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.control-item {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			border: 1px solid rgba(239, 239, 240, 0.7);
			background-color: var(--doc-dark-grey);
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 5;
			i {
				font-size: 20px;
			}
		}
	}
}
.patient-header {
	box-sizing: border-box;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	background-color: var(--doc-green);
	color: var(--doc-white);
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-height: 70px;
	overflow: hidden;
	pointer-events: none;
	padding-right: 20px;
	padding-left: 20px;
	z-index: 101;
	> img {
		max-height: 50px;
		max-width: 40%;
	}
}
@media screen and (max-width: 500px) {
	.patient-header {
		img.hide-on-sm {
			display: none;
		}
	}
}
@media screen and (min-width: 501px) {
	.patient-header {
		img.show-on-sm {
			display: none;
		}
	}
}
