.pure-material-checkbox {
	z-index: 0;
	position: relative;
	display: inline-block;
	color: rgba(0, 0, 0, 0.87);
	line-height: 1.5;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 5px;
	/* Input */
	> input {
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		z-index: -1;
		position: absolute;
		left: -10px;
		top: -8px;
		display: block;
		margin: 0;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		background-color: rgba(0, 0, 0, 0.6);
		box-shadow: none;
		outline: none;
		opacity: 0;
		transform: scale(1);

		transition: opacity 0.3s, transform 0.2s;
	}
	/* Span */
	> span.label {
		display: inline-block;
		width: 100%;
		pointer-events: all;
	}

	/* Box */
	> span.label::before {
		content: '';
		display: inline-block;
		box-sizing: border-box;
		margin: 3px 11px 3px 1px;
		border: solid 2px; /* Safari */
		border-color: rgba(0, 0, 0, 0.6);
		border-radius: 2px;
		width: 18px;
		height: 18px;
		vertical-align: top;
		transition: border-color 0.2s, background-color 0.2s;
	}

	/* Checkmark */
	> span.label::after {
		content: '';
		display: block;
		position: absolute;
		top: 3px;
		left: 1px;
		width: 10px;
		height: 5px;
		border: solid 2px transparent;
		border-right: none;
		border-top: none;
		transform: translate(3px, 4px) rotate(-45deg);
	}

	/* Checked, Indeterminate */
	> input:checked,
	> input:indeterminate {
		background-color: var(--doc-green);
	}
	> input:checked + span::before,
	> input:indeterminate + span::before {
		border-color: var(--doc-green);
		background-color: var(--doc-green);
	}
	> input:checked + span::after,
	> input:indeterminate + span::after {
		border-color: var(--doc-white);
	}

	> input:indeterminate + span::after {
		border-left: none;
		transform: translate(4px, 3px);
	}

	/* Hover, Focus */
	&:hover > input {
		opacity: 0.04;
	}

	> input:focus {
		opacity: 0.12;
	}

	&:hover > input:focus {
		opacity: 0.16;
	}

	/* Active */
	> input:active {
		opacity: 1;
		transform: scale(0);
		transition: transform 0s, opacity 0s;
	}

	> input:active + span::before {
		border-color: var(--doc-green);
	}
	> input:checked:active + span::before {
		border-color: transparent;
		background-color: rgba(0, 0, 0, 0.6);
	}
}

.pure-material-checkbox.covermore {
	> input:checked,
	> input:indeterminate {
		background-color: var(--cov-blue);
	}

	> input:checked + span::before,
	> input:indeterminate + span::before {
		border-color: var(--cov-blue);
		background-color: var(--cov-blue);
	}

	> input:active + span::before {
		border-color: var(--cov-blue);
	}
}
