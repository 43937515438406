// Top Navigation
.top-navigation-container {
	a {
		text-decoration: none;
		color: var(--doc-white);
		&:hover {
			color: var(--doc-dark-grey);
			cursor: pointer;
		}
		.non-active-icon {
			color: var(--doc-dark-grey) !important;
		}
	}
	z-index: 9999;
	height: 66px;
	width: 100vw;
	align-items: center;
	background-color: var(--doc-green);
	color: var(--doc-light-grey);
	padding: 0px 21px;
	box-sizing: border-box;
	position: fixed;
	left: 0px;
	top: 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.page-title {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		margin: 0px;
		font-size: 25px;
		line-height: 24px;
		color: var(--doc-white);
	}
	.practice-logo {
		padding-left: 4px;
		img {
			height: 42px;
		}
	}
	.claimable-notification {
		position: absolute;
		right: 90px;
	}
	.personal-profile {
		color: var(--doc-light-grey);
		font-size: 36px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-self: center;
		text-align: center;
		position: relative;
		padding-right: 12px;
		.personal-profile-actions {
			position: absolute;
			top: 47px;
			right: 0px;
			.MuiPaper-root {
				margin: 0px !important;
				.row.space-between {
					margin: 0px !important;
					.btn {
						margin-top: 0px;
						margin-left: 5px;
						margin-right: 5px;
					}
				}
			}
		}
		&:hover {
			color: var(--doc-dark-grey);
		}
	}
	.personal-profile.active {
		color: var(--doc-dark-grey);
	}
}
// Side Navigation

.side-navigation-container {
	a:hover {
		color: var(--doc-green);
	}
	height: 100vh;
	padding-top: 70px;
	background-color: var(--doc-dark-grey);
	color: var(--doc-white);
	display: flex;
	flex-direction: row;
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 9;
	.side-navigation-menu {
		max-width: 70px;
		min-width: 70px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		.side-navigation-tab {
			height: 50px;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			cursor: pointer;
		}
		.side-navigation-tab.active {
			color: var(--doc-green);
		}
	}
	.side-navigation-content {
		min-width: 200px;
		width: 350px;
		height: 100%;
		background-color: var(--doc-white);
		color: var(--doc-dark-grey);
		border-right: 2px solid var(--doc-pink);
		padding: 20px;
		box-sizing: border-box;
	}
	.side-navigation-title {
		margin-right: 20px;
	}
}
// Tablet

@media only screen and (max-width: 800px) {
	.practice-logo {
		top: 10px;
		> img {
			height: 42px;
		}
	}
}

.active-icon {
	fill: var(--doc-pink) !important;
	color: var(--doc-pink) !important;
}
.usual-icon {
	fill: var(--doc-dark-grey) !important;
	color: var(--doc-dark-grey) !important;
}

@media only screen and (max-width: 760px) {
	.page-title {
		display: none;
	}
}
// Mobile
@media only screen and (max-width: 600px) {
	.practice-logo {
		padding-left: 0px !important;
		position: absolute;
		left: 85px;
		top: 10px;
		transform: translateX(-50%);
	}
	.practice-logo.vista-logo {
		left: 195px;
	}
	.practice-logo.vista-logo.extended {
		left: 240px;
	}
	.practice-logo.extended {
		left: 130px;
	}
}
