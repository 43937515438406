// .patient-video {
// 	.full-screen-overlay-container {
// 		height: 100% !important;
// 		width: 100% !important;
// 	}
// }
.full-screen-overlay-container {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 99;
	background-color: var(--doc-dark-grey);
	position: absolute;
	top: 0px;
	left: 0px;
	.full-screen-overlay-content {
		background-color: var(--doc-white);
		color: var(--doc-dark-grey);
		width: 500px;
		max-width: 80%;
		min-height: 350px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px;
		border-radius: 4px;
		box-shadow: 0px 4px 36px rgba(255, 255, 255, 0.25);
	}
}
