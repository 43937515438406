.appointment-calendar-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 400px;
	max-width: 85vw;
	box-sizing: border-box;
	.appointment-guide {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
		.guide {
			> span {
				padding-left: 5px;
			}
			margin-top: 2px;
			margin-bottom: 2px;
		}
		.selected {
			> i {
				color: var(--doc-pink);
			}
		}
		.available {
			> i {
				color: var(--doc-green);
			}
		}
	}
}
.form-action-buttons {
	width: 100%;
	max-width: 90vw;
	@media screen and (min-width: 1024px) {
        max-width: 45vw;
    }
	.action-btn {
		padding: 0px 15px;
		height: 'fit-content';
		min-height: 30px;
		max-height: 60px;
	}
	.first-btn {
		margin-right: 20px;
	}
	.last-btn {
		margin-left: 10px;
	}
}
.underline {
	text-decoration: underline;
}
.partners-logo {
	height: 110px;
    position: absolute;
    right: 20px;
    top: 2px;
}
.aw-logo {
	height: 40px;
    right: 20px;
    top: 2px;
}
.aw-box {
	margin-top: 30px;
}
.partners-box {
	padding: 0px 25px;
    position: relative;
    margin-bottom: 0 !important;
}
.florida-title {
	margin-top: 40px;
}
.additional-option-text {
	font-size: 12;
	font-style: italic;
}
.title-text {
	font-size: 24px;
}
.gray-text {
	color: var(--doc-light-grey);
}
.autocomplete {
	.MuiAutocomplete-clearIndicator {
		display: none;
	}
}
.fixed-box {
	@media screen and (min-width: 1024px) {
        position: fixed;
    }
	top: 25%;
	right: 3%;
	text-align: center;
	padding: 10px;
	min-width: 280px;
	max-width: 380px;
}
.summary-box {
	background: white;
	padding: 20px;
	text-align: left;
	border-radius: 10px;
	margin-bottom: 20px;
	max-width: 382px;
	min-width: 252px;
	box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.5);
}
.countdown-timer {
	text-align: center;
	padding: 10px;
	max-width: 400px;
	min-width: 280px;
	border: 1px solid var(--doc-green);
}
.appointment-slot-container {
	width: 522px;
	max-width: 95%;
	.slot {
		height: 40px;
		min-width: 75px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		font-size: 14px;
		border: 1px solid var(--doc-green);
		margin: 5px;
		color: var(--doc-dark-grey);
		transition: 0.4s;
		&:hover {
			cursor: pointer;
			background-color: var(--doc-green);
			color: var(--doc-white);
		}
	}
	.slot.selected {
		color: var(--doc-white);
		background-color: var(--doc-pink);
		border-color: var(--doc-pink);
		&:hover {
			cursor: pointer;
			background-color: var(--doc-pink);
			color: var(--doc-white);
			opacity: 0.7;
		}
	}
	.slot-container {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		justify-content: flex-start;
	}
}
.row.special-row {
	flex-wrap: wrap;
	align-items: flex-start;
}
#appointments {
	margin-top: 0px;
}
.confirmation-container {
	display: flex;
	align-items: flex-start;
	padding-top: 20px;
	.tick {
		margin-left: 5px;
		margin-right: 20px;
		> img {
			height: 35px;
			width: 35px;
		}
	}
}

.trc-description {
	max-width: 50%;
	@media screen and (max-width: 1024px) {
		max-width: 90vw;
	}
}

.list-title {
	color: var(--doc-green) !important;
	font-weight: bold;
}

.step-box {
	.item-input {
        width:100%;
        min-width: 270px;
		max-width: 450px;
    }
}

.step-0-box {
	.MuiCheckbox-colorSecondary.Mui-checked {
		color: var(--doc-green);
	}
	h4 {
		margin: 0px;
		padding-top: 20px;
	}
}

.step-1-box {
	h5 {
		max-width: 400px;
	}
}

@media screen and (max-width: 600px) {
	.tooltip-img {
		width: 70%;
		height: 50%;
	}
	.confirmation-container {
		.MuiStepContent-last {
			padding-left: 0px;
		}
	}
}
