.patient-notes-container {
	flex: 1;
	align-self: flex-start;
	.tab-container {
		height: calc(100vh - 130px);
		display: flex;
		overflow: scroll;
		flex-direction: column;
		background-color: var(--doc-white);
		border-radius: 5px;
		overflow: hidden;
		max-width: 40vw;
		padding: 10px;
		.tab-content {
			height: 100%;
   			overflow-y: auto;
			overflow-x: hidden;
			padding-right: 5px;
		}
		/* Customize website's scrollbar like Mac OS
		Not supports in Firefox and IE */

		/* total width */
		.tab-content::-webkit-scrollbar {
			background-color: rgba(255, 255, 255, 0.5);
			width: 8px;
			-webkit-appearance: none;
		}
		/* background of the scrollbar except button or resizer */
		.tab-content::-webkit-scrollbar-track {
			background-color: rgba(255, 255, 255, 0.5);
		}

		/* scrollbar itself */
		.tab-content::-webkit-scrollbar-thumb {
			background-color: #babac0;
			border-radius: 16px;
			border: 4px solid rgba(0, 0, 0, 0.35);
		}

		/* set button(top and bottom of the scrollbar) */
		.tab-content::-webkit-scrollbar-button {
			display: none;
		}
		.full-height {
			min-height: 100%;
		}
	}
	.appointment-notes {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.tab-row-text {
		margin: 0;
		margin-top: 10px;
	}
	.title-info {
		font-weight: bold;
	}
	.patient-link-text {
		max-width: 265px;
		cursor: pointer;
	}
	.padding-top-box {
		padding-top: 20px;
	}
	.appointment-buttons {
		width: 100%;
		.row {
			justify-content: space-around;
			align-items: center;
		}
	}
	.patient-notes {
		.row {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}
.patient-notes-container.face-to-face {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: flex-start;
	.patient-notes {
		width: 100%;
		padding: 10px;
	}
	.appointment-notes {
		width: 100%;
		padding: 10px;
	}
	.appointment-buttons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: space-between;
		.row {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
		}
	}
}
.patient-video {
	flex: 2;
	margin: 10px;
	position: relative;
	height: calc(100vh - 110px);
}
.screen-container {
	max-width: 95%;
	margin: auto;
	.row {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.row.flex-start {
		align-items: stretch;
	}
}
.past-patient-notes {
	width: 100%;
}
.expanding-image {
	&:hover {
		cursor: pointer;
	}
}
.circle-right {
	width: 14px;
	height: 14px;
	border-radius: 50%;
	margin-right: 10px;
}
.status-online {
	background-color: var(--doc-green);
}
.status-offline {
	background-color: var(--doc-pink);
}
