.alert-box-container {
	box-sizing: border-box;
	border-radius: 4px;
	i {
		&:hover {
			cursor: pointer;
		}
	}
	.alert-box {
		width: 100%;
		min-height: 56px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 3px;
		padding-left: 10px;
		padding-right: 10px;
		> span {
			font-weight: 500;
			width: 100%;
			display: flex;
		}
	}

	.alert-box.error {
		border: 4px solid rgba(229, 1, 77, 0.2);
		> i {
			color: var(--doc-pink);
		}
	}
	.alert-box.success {
		border: 4px solid rgba(0, 189, 175, 0.2);
		i > {
			color: var(--doc-green);
		}
	}
}
.alert-box-container.error {
	border: 1px solid var(--doc-pink);
}
.alert-box-container.success {
	border: 1px solid var(--doc-green);
}
