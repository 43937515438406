.answer-video {
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: -1;
	height: 100%;
	width: 100%;
	max-width: 100%;
	max-height: 100%;
}
.answer-video-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	video {
		object-fit: contain;
		background-color: var(--doc-dark-grey);
	}
	.captured-image-box {
		border-radius: 20px;
		background-color: rgba(51, 51, 51, 0.7);
		padding: 20px;
		position: absolute;
		margin: auto;
		max-width: 70%;
		.qrcode-text {
			margin-top: 0;
			color: var(--doc-white);
			font-weight: 500;
			font-size: 20px;
		}
		p {
			margin-top: 0;
			color: var(--doc-white);
			font-weight: 500;
			font-size: 20px;
		}
		.captured-img {
			width: 100%;
			height: 100%;
			min-height: 150px;
		}
		.captured-btn {
			width: 100%;
		}
	}

}
.answer-video-container {
	.nobody-present {
		height: 100%;
		width: 100%;
		background-color: var(--doc-dark-grey);
		color: var(--doc-white);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		opacity: 1;
		transition: opacity 0.5s ease-out;
	}
	.nobody-present.hidden {
		opacity: 0;
		display: none;
	}

	.video-and-overlay {
		transition: all linear 0.5s;
		overflow: hidden;
		height: 100%;
		width: 100%;
		pointer-events: none;
		.caller-video {
			max-width: 300px;
			max-height: 30%;
			box-shadow: 0px 0px 5px 2px rgba(51, 51, 51, 1);
			border-radius: 5px;
		}
	}
	.video-and-overlay.hidden-video {
		width: 100%;
		height: 100%;
		left: 0px;
	}
	.video-and-overlay.photo {
		position: fixed;
		width: 90%;
		max-width: 1400px;
		z-index: 101;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.caller-video {
			width: 100%;
			max-width: 100%;
			video {
				object-fit: cover;
			}
		}
	}
	.overlay {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		background-color: rgba(51, 51, 51, 0.7);
		color: var(--doc-white);
		padding: 5px;
		> i {
			font-size: 2em;
		}
	}
	.overlay-close {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		background-color: rgba(51, 51, 51, 0.7);
		color: var(--doc-white);
		padding: 5px;
		opacity: 0;
		transition: all 0.3s linear;
		> i {
			font-size: 2em;
		}
		&:hover {
			opacity: 1;
		}
	}
}
.overlay-outline {
	height: 85%;
	width: 85%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 5;
	position: absolute;
	padding: 0px;
	display: flex;
	pointer-events: none;

	.box {
		box-sizing: border-box;
		height: 100%;
		max-height: 100%;
		pointer-events: none;
	}
	.left.box {
		width: 20%;
		border-left: 4px dashed red;
		border-top: 4px dashed red;
		border-bottom: 4px dashed red;
	}
	.right.box {
		width: 20%;
		border-right: 4px dashed red;
		border-top: 4px dashed red;
		border-bottom: 4px dashed red;
	}
	.center.box {
		width: 60%;
	}
}
@media only screen and (max-width: 768px) {
	.video-and-overlay.photo {
		max-width: 100%;
		.caller-video {
			max-height: 100%;
			width: 100%;
		}
	}
	.video-and-overlay {
		max-width: 60%;
		.caller-video {
			max-width: 100%;
		}
	}
	.video-and-overlay.hidden-video {
		min-width: 60px;
		max-width: 20%;
		.caller-video {
			overflow: hidden;
		}
	}
}
